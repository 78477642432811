<template>
  <div class="container text-center">

    <div class="row mt-5 mb-4 justify-content-center">
      <div class="col-12 col-md-8 col-lg-6 col-xl-4 text-center">

        <nuxt-link :to="`/${locale ? locale.code : ''}`">
          <img src="/img/logo.svg" alt="Logo" class="img-responsive">
        </nuxt-link>
      </div>
    </div>

    {{ error }}
    <h1 v-if="Number(error.statusCode) === 404">{{ error.statusMessage }}</h1>
    <h1 v-else>An error occurred</h1>
    <NuxtLink :to="homePage">{{ $t('back-to-home-page') }}</NuxtLink>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref, useState} from "#imports";
import {useNuxtApp} from "#app";

const locale = useState('locale');
const props = defineProps({
  error: Object
})

const {$i18n} = useNuxtApp();
const homePage = ref("/");


onMounted(() => {
  if (window.location.pathname.length > 1 && ['sk', 'pl', 'en', 'cz', 'hu'].some(i => i === window.location.pathname.substr(1, 2))) {
    homePage.value = window.location.pathname.substr(0, 3);
  }
})

</script>
